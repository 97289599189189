import React, { useEffect, useState } from 'react';
import { Form, Grid, Input, Tooltip } from 'antd';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { InputMaskCnpj } from 'commons/InputMaskCnpj';
import { cnpj } from 'helpers/cnpj';
import {
  StBackButton,
  StBanner,
  StBannerContainer,
  StButton,
  StContainer,
  StDivider,
  StFormContainer,
  StFormTitle,
  StLink,
  StLogo,
  StPage,
  StText,
} from './styled';

const baseURL = process.env.REACT_APP_AWS_V4_ASSETS;
const logo = `${baseURL}/login/logo.png`;
const graphMan = `${baseURL}/img/graphMan.png`;

export const RegisterStep2 = ({
  initialValues,
  onFinish = () => {},
  loading = false,
  onClickBackStep = () => {},
  companyNameFetch = () => {},
  companyNameLoading = false,
  companyNameError = false,
  companyName = '',
  dataTestPrefix,
}) => {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const [form] = Form.useForm();
  const [refetchButtonVisible, setRefetchButtonVisibility] = useState(false);

  const onFetchCompanyName = (cnpjValue) => {
    companyNameFetch(cnpjValue);
  };

  const onCnpjChange = (e) => {
    setRefetchButtonVisibility(false);
    const cnpjValue = e.target.value;

    if (cnpj.isValid(cnpjValue)) {
      onFetchCompanyName(cnpjValue);
    }
  };

  const setCompanyNameError = (error = []) => {
    form.setFields([
      {
        name: 'companyName',
        errors: [error],
      },
    ]);
  };

  const onClickFinish = (values) => {
    if (companyNameError) {
      setCompanyNameError(companyNameError);
      return;
    }

    onFinish(values);
  };

  useEffect(() => {
    if (form.getFieldValue('cnpj') && companyName) {
      form.setFieldValue('companyName', companyName);
    }
    // eslint-disable-next-line
  }, [companyName]);

  useEffect(() => {
    if (form.getFieldValue('cnpj') && companyNameError) {
      setCompanyNameError(companyNameError);
      setRefetchButtonVisibility(true);
    } else {
      setCompanyNameError([]);
    }
    // eslint-disable-next-line
  }, [companyNameError]);

  return (
    <StPage $breakpoint={breakpoint}>
      <StContainer $breakpoint={breakpoint}>
        <StFormContainer>
          <StBackButton
            type="link"
            disabled={loading}
            onClick={onClickBackStep}
            {...dataTestIdHandler(dataTestPrefix, 'step-back')}
          >
            <ArrowLeftOutlined /> Voltar etapa
          </StBackButton>
          <StLogo src={logo} alt="Logo Concil Card" />
          <StFormTitle>Criar conta</StFormTitle>
          <Form
            form={form}
            initialValues={initialValues}
            layout="vertical"
            requiredMark={false}
            onFinish={onClickFinish}
            style={{ maxWidth: '400px', margin: '0 auto' }}
          >
            <Form.Item
              name="cnpj"
              label="CNPJ"
              rules={[
                { required: true, message: 'Campo obrigatório' },
                {
                  validator: (_, value) => {
                    if (!value || cnpj.isValid(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('CNPJ inválido'));
                  },
                },
              ]}
            >
              <InputMaskCnpj
                placeholder="Insira o CNPJ"
                suffix={
                  companyNameLoading ? (
                    <LoadingOutlined />
                  ) : refetchButtonVisible ? (
                    <Tooltip title="Buscar razão social novamente">
                      <ReloadOutlined
                        onClick={() =>
                          onFetchCompanyName(form.getFieldValue('cnpj'))
                        }
                      />
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
                disabled={companyNameLoading || loading}
                onChange={onCnpjChange}
                {...dataTestIdHandler(dataTestPrefix, 'cnpj')}
              />
            </Form.Item>

            <Form.Item name="companyName" label="Razão social">
              <Input
                disabled
                placeholder="Razão social"
                {...dataTestIdHandler(dataTestPrefix, 'companyname')}
              />
            </Form.Item>

            <StDivider plain>Etapa 2 de 2</StDivider>

            <Form.Item>
              <StButton
                type="primary"
                htmlType="submit"
                disabled={companyNameLoading}
                loading={loading}
                {...dataTestIdHandler(dataTestPrefix, 'create-account-submit')}
              >
                Criar conta
              </StButton>
            </Form.Item>
          </Form>
          <StText>
            Já possui conta?{' '}
            <StLink to="/" {...dataTestIdHandler(dataTestPrefix, 'login-link')}>
              Fazer login
            </StLink>
          </StText>
        </StFormContainer>
        <StBannerContainer>
          <StBanner>
            Crie sua conta e comece a gestão do seu negócio com o nosso plano
            gratuito
          </StBanner>
          <img
            src={graphMan}
            alt="Pessoa apontando para um gráfico"
            width={342}
          />
        </StBannerContainer>
      </StContainer>
    </StPage>
  );
};
