import { lambdaApi } from 'services/api';

export const getDetail = async (idtSummary) => {
  const config = {
    params: {
      idt_summary: idtSummary,
    },
  };

  const result = await lambdaApi().get('/onboarding/detail', config);
  return result?.data?.data;
};
