import React, { useContext } from 'react';
import { OnboardingManagementContext } from 'components/OnboardingManagement/OnboardingManagement';
import { ActionContainer, ActionText, EditIcon } from './styled';

export function Action({ record }) {
  const { openModal } = useContext(OnboardingManagementContext);

  return (
    <ActionContainer>
      <EditIcon />
      <ActionText onClick={() => openModal(record)}>
        Conferir e editar
      </ActionText>
    </ActionContainer>
  );
}
