import React, { useContext, useState } from 'react';
import { Button, Spin, Timeline, Tooltip } from 'antd';
import { OnboardingManagementContext } from 'components/OnboardingManagement/OnboardingManagement';
import {
  modalStepsParser,
  stageOptions,
} from 'components/OnboardingManagement/constants/constants';
import { colors } from 'styles/colors';
import * as St from './styled';

export function Modal() {
  const {
    isModalVisible,
    modalData,
    closeModal,
    steps,
    isModalLoading,
    patchOnboardingStatus,
  } = useContext(OnboardingManagementContext);
  const { idtOnboardingSummary, branchCode, branchName, stage } = modalData;
  const [selectStage, setSelectStage] = useState(stage);

  return (
    <St.OnboardingModal
      open={isModalVisible}
      footer={null}
      closable={false}
      width="973px"
      height="700px"
    >
      <St.TitleContainer>
        <St.Title>Detalhes da implantação</St.Title>
        <St.CloseIcon onClick={closeModal} />
      </St.TitleContainer>

      <St.BranchContainer>
        <St.BranchColumn width={300}>
          <St.Text>Código da empresa</St.Text>
          <St.Input value={branchCode} disabled />
        </St.BranchColumn>

        <St.BranchColumn width={300}>
          <St.Text>Nome da empresa</St.Text>
          <St.Input value={branchName} disabled />
        </St.BranchColumn>

        <St.BranchColumn width={293}>
          <St.Text>Estágio</St.Text>
          <St.Select
            options={stageOptions}
            disabled={isModalLoading}
            value={selectStage}
            onChange={(_, option) => {
              setSelectStage(option.label);
            }}
          />
        </St.BranchColumn>
      </St.BranchContainer>

      <St.StepsContainer>
        {isModalLoading ? (
          <St.SpinContainer>
            <Spin />
          </St.SpinContainer>
        ) : (
          <Timeline>
            {steps?.map(({ order, title, message, status }) => {
              const stepTitle = `${order}. ${title}`;

              return (
                <St.TimelineItem
                  key={`order-${order}`}
                  $status={status}
                  dot={
                    <>
                      <Tooltip
                        title={status}
                        color={colors.gray9}
                        placement="right"
                      >
                        <St.IconContainer>
                          {modalStepsParser[status]?.icon}
                        </St.IconContainer>
                      </Tooltip>
                    </>
                  }
                >
                  <St.StepTitle $status={status}>{stepTitle}</St.StepTitle>
                  <St.StepDescription>{message}</St.StepDescription>
                </St.TimelineItem>
              );
            })}
          </Timeline>
        )}
      </St.StepsContainer>

      <St.Divider />

      <St.FooterContainer>
        <St.CancelBtn disabled={isModalLoading} onClick={closeModal}>
          Cancelar
        </St.CancelBtn>
        <Button
          type="primary"
          disabled={isModalLoading}
          onClick={() =>
            patchOnboardingStatus(idtOnboardingSummary, selectStage)
          }
        >
          Salvar
        </Button>
      </St.FooterContainer>
    </St.OnboardingModal>
  );
}
