/* eslint-disable no-use-before-define */
import { useCallback, useEffect, useState } from 'react';
import { Form, notification } from 'antd';
import ColumnList from '../components/Table/ColumnsList';
import getListOfPlansFromAPI from 'services/listOfPlans';
import { getStatus } from 'services/onboardingManagement/getStatus';
import { getSummary } from 'services/onboardingManagement/getSummary';
import { getDetail } from 'services/onboardingManagement/getDetail';
import { patchStatus } from 'services/onboardingManagement/patchStatus';
import {
  rowClassName,
  stageOptions,
  tableStatusParser,
} from '../constants/constants';
import { perPageOptions, showTotalPage } from 'constants/perPageOptions';
import { onColumnSort } from 'helpers/sorter';
import errorHandler from 'helpers/errorHandler';
import { useWindowSize } from 'utils/hooks/useWindowSize';

export const useOnboardingManagement = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [changeCalendarIconColor, setChangeCalendarIconColor] = useState(false);
  const [plans, setPlans] = useState([{}]);
  const [status, setStatus] = useState([]);
  const [data, setData] = useState([]);
  const [columnList, setColumnList] = useState();
  const [pageCurrent, setPageCurrent] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [sortFields, setSortFields] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [steps, setSteps] = useState([]);
  const [modalData, setModalData] = useState({
    idtOnboardingSummary: '',
    branchCode: '',
    branchName: '',
    stage: false,
  });
  const { width } = useWindowSize();
  const smallScreen = width <= 1366;

  const initialValues = useCallback(() => {
    return {
      date: ['', ''],
      codClient: '',
      stage: '',
      plan: '',
      status: '',
    };
  }, []);

  const rules = {
    date: {
      required: true,
      async validator(_, value) {
        await new Promise((resolve, reject) => {
          const firstDate = value && value[0];
          const secondDate = value && value[1];

          if (
            value === null ||
            value === undefined ||
            firstDate === null ||
            secondDate === null
          ) {
            setChangeCalendarIconColor(true);
            reject(new Error('Campo obrigatório'));
          } else {
            setChangeCalendarIconColor(false);
            resolve();
          }
        });
      },
    },
    codClient: {
      async validator() {
        await new Promise((resolve, reject) => {
          if (form.getFieldError('codClient').length > 0) {
            reject(new Error(form.getFieldError('codClient')[0]));
          } else {
            resolve();
          }
        });
      },
    },
  };

  const pagination = {
    pageSize: perPage,
    pageSizeOptions: perPageOptions,
    total,
    current: pageCurrent,
    showTotal: showTotalPage,
    showSizeChanger: Boolean(total >= 10),
  };

  const onTablePaginationOrFiltersOrSorterChange = async (
    paginationHandler,
    tableFilters,
    sorter
  ) => {
    onColumnSort(sorter, setSortFields);
    setPerPage(paginationHandler.pageSize);
    setPageCurrent(paginationHandler.current);
  };

  const getPlans = useCallback(async () => {
    try {
      const { plans: apiPlans } = await getListOfPlansFromAPI();
      setPlans(apiPlans);
    } catch (error) {
      setPlans([{}]);
      errorHandler('Ocorreu um erro:', error);
    }
  }, []);

  const getOnboardingStatus = useCallback(async () => {
    try {
      const values = await getStatus();
      setStatus(values);
    } catch (error) {
      setStatus([]);
      errorHandler('Ocorreu um erro:', error);
    }
  }, []);

  const getStepsDetails = useCallback(async (idtSummary) => {
    setIsModalLoading(true);

    try {
      const { steps: apiSteps } = await getDetail(idtSummary);
      setSteps(apiSteps);
    } catch (error) {
      setSteps([]);
      errorHandler('Ocorreu um erro:', error);
    } finally {
      setIsModalLoading(false);
    }
  }, []);

  const patchOnboardingStatus = useCallback(async (idtSummary, stage) => {
    setIsModalLoading(true);

    try {
      const payload = {
        idt_summary: idtSummary,
        stage: stage?.toUpperCase(),
      };

      const result = await patchStatus(payload);

      notification.success({ message: result?.detail });
      await Promise.all([getStepsDetails(idtSummary), getOnboardingSummary()]);
    } catch (error) {
      errorHandler('Ocorreu um erro:', error);
    } finally {
      setIsModalLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormValues = (obj) => {
    let result = {};

    Object.entries(obj).forEach(([key, value]) => {
      if (value && value !== '') {
        result = {
          ...result,
          [key]: value,
        };
      }

      if (key === 'stage' && value === false) {
        result = {
          ...result,
          [key]: value,
        };
      }
    });

    return result;
  };

  const handleFilterValues = useCallback(
    ({ codClient = '', plan = '', status: _status = '', stage = '' }) => {
      const result = [];

      if (codClient) {
        result.push({
          name: 'cod_client',
          val: [codClient],
        });
      }
      if (plan) {
        result.push({ name: 'plan', val: [plan] });
      }
      if (_status) {
        result.push({ name: 'status', val: [_status] });
      }
      if (stage !== undefined && stage !== '') {
        result.push({ name: 'stage', val: [stage] });
      }

      return result;
    },
    []
  );

  const handleSortFields = useCallback((fields) => {
    let result = {};

    Object.entries(fields).forEach(([key, value]) => {
      if (value === 'ascend') {
        result = {
          ...result,
          [key]: 'asc',
        };
      }

      if (value === 'descend') {
        result = {
          ...result,
          [key]: 'desc',
        };
      }
    });

    if (Object.keys(result).length === 0) {
      return undefined;
    }

    return result;
  }, []);

  const getOnboardingSummary = useCallback(async () => {
    const startDate = form.getFieldValue('date')[0].format('YYYY-MM-DD');
    const endDate = form.getFieldValue('date')[1].format('YYYY-MM-DD');
    const filtersValues = handleFilterValues(form.getFieldsValue(true));
    const parsedSortFields = handleSortFields(sortFields);

    setLoading(true);

    try {
      const { results, meta } = await getSummary({
        page: pageCurrent,
        perPage,
        sortFields: parsedSortFields,
        startDate,
        endDate,
        q: { filter: filtersValues },
      });
      setData(results);
      setTotal(meta?.total);
    } catch (error) {
      setData([]);

      if (error?.response?.data?.data?.detail === 'Número inválido') {
        form.setFields([
          {
            name: 'codClient',
            errors: [error?.response?.data?.data?.detail],
          },
        ]);
      } else {
        errorHandler('Ocorreu um erro:', error);
      }
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, handleFilterValues, pageCurrent, perPage, sortFields]);

  const onFinish = async () => {
    await getOnboardingSummary(handleFormValues(form.getFieldsValue(true)));
  };

  const resetForm = () => {
    setData([]);
    setPageCurrent(1);
    setPerPage(10);
    setSortFields({});
    return form.resetFields();
  };

  const openModal = (record) => {
    const idtOnboardingSummary = record?.idt_onboarding_summary;
    const [codClient, clientName] = record?.client
      ?.match(/^([^\s]+)\s+(.*)$/)
      .slice(1) || ['', ''];

    setModalData({
      idtOnboardingSummary,
      branchCode: codClient,
      branchName: clientName,
      stage: record?.stage,
    });

    getStepsDetails(idtOnboardingSummary);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setModalData({
      branchCode: '',
      branchName: '',
      stage: false,
    });

    setSteps([]);
    setIsModalVisible(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        await Promise.all([getPlans(), getOnboardingStatus()]);
      } catch (error) {
        errorHandler('Ocorreu um erro:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [getPlans, getOnboardingStatus]);

  useEffect(() => {
    if (form.getFieldValue('date') && form.getFieldValue('date').length > 0) {
      getOnboardingSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCurrent, perPage, sortFields]);

  useEffect(() => {
    setColumnList(ColumnList(smallScreen));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    form,
    rules,
    stageOptions,
    initialValues,
    resetForm,
    loading,
    setLoading,
    changeCalendarIconColor,
    setChangeCalendarIconColor,
    onFinish,
    plans,
    status,
    data,
    columnList,
    rowClassName,
    tableStatusParser,
    pagination,
    onTablePaginationOrFiltersOrSorterChange,
    sortFields,
    isModalVisible,
    setIsModalVisible,
    modalData,
    setModalData,
    openModal,
    closeModal,
    getStepsDetails,
    steps,
    isModalLoading,
    setIsModalLoading,
    patchOnboardingStatus,
  };
};
