import {
  Divider as AntDivider,
  Input as AntInput,
  Modal,
  Select as AntSelect,
  Typography,
  Timeline,
  Button,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { modalStepsParser } from 'components/OnboardingManagement/constants/constants';
import { colors } from 'styles/colors';
import styled from 'styled-components';

const { Title: AntTitle, Text: AntText } = Typography;

export const OnboardingModal = styled(Modal)`
  &&& {
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid ${colors.gray4};
`;

export const Title = styled(AntTitle)`
  &&& {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: ${colors.gray9};
  }
`;

export const CloseIcon = styled(CloseOutlined)`
  &&& {
    cursor: pointer;
    color: ${colors.gray7};
  }
`;

export const BranchContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 27px;
`;

export const BranchColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width && `${width}px`};
`;

export const Text = styled(AntText)`
  &&& {
    font-size: 14px;
    color: ${colors.gray9};
  }
`;

export const Input = styled(AntInput)`
  &&& {
    margin-top: 8.42px;
  }
`;

export const Select = styled(AntSelect)`
  &&& {
    margin-top: 8.42px;

    .ant-select-arrow {
      color: ${colors.gray7};
    }

    .ant-select-selector > .ant-select-selection-item {
      color: ${({ disabled }) =>
        disabled ? `${colors.gray6}` : `${colors.gray9}`};
    }
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  height: 390px;
  margin-top: 33px;
  overflow: auto;
  padding: 16px 24px;
`;

export const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TimelineItem = styled(Timeline.Item)`
  &&& {
    .ant-timeline-item-content {
      display: flex;
      flex-direction: column;
      margin: 0 0 0 34px;
    }

    .ant-timeline-item-tail {
      border: ${({ $status }) =>
        $status && `1px solid ${modalStepsParser[$status]?.tailColor}`};
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`;

export const StepTitle = styled(Text)`
  &&& {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${({ $status }) =>
      $status === 'PENDENTE' ? `${colors.gray7}` : `${colors.gray9}`};
  }
`;

export const StepDescription = styled(StepTitle)`
  &&& {
    color: ${colors.gray7};
    margin-top: 8px;
    margin-bottom: 10px;
  }
`;

export const Divider = styled(AntDivider)`
  &&& {
    min-width: 0;
    width: 973px;
    margin: 0;
    margin-left: -24px;
    margin-top: 11px;
    margin-bottom: 11px;
    border-top: 1px solid ${colors.gray4};
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
`;

export const CancelBtn = styled(Button)`
  &&& {
    color: ${({ disabled }) =>
      disabled ? `${colors.gray6}` : `${colors.gray9}`};
  }
`;
