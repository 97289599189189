import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import OtpInput from 'react-otp-input';
import { Button } from 'antd';
import { dataTestIdHandler } from 'helpers/dataTestIdHandler';
import {
  StContainer,
  StOtpContainer,
  StContent,
  StTitle,
  StVerificationCodeTitle,
  StVerificationCodeContent,
  otpInputStyle,
  otpInputErrorStyle,
  StOtpInputContainer,
  StButtonLink,
  StOtpInputErrorMessage,
  StTimerLabel,
  StTimer,
} from './styled';

export const RegisterOtp = ({
  otpValue,
  setOtpValue,
  isOtpError,
  setOtpError,
  onSubmit,
  onRequestNewCode,
  submitLoading,
  countdown = 120,
  dataTestPrefix,
}) => {
  const otpLength = 6;
  const isOtpCompleted = otpValue.length === otpLength;
  const [time, setTime] = useState(countdown);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let interval = null;
    if (isActive && time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
    } else if (time === 0) {
      setIsActive(false);
      setOtpError(true);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [isActive, time]);

  const resetTimer = () => {
    setTime(countdown);
    setIsActive(true);
  };

  const formatTime = (_time) => {
    return dayjs().startOf('day').add(_time, 'second').format('mm:ss');
  };

  const onChangeOtpInput = (otp) => {
    setOtpValue(otp);
    setOtpError(false);
  };

  const onClickSubmit = () => {
    if (time > 0) {
      onSubmit();
    }
  };

  const onClickRequestNewCode = () => {
    setOtpValue('');
    setOtpError(false);
    resetTimer();
    onRequestNewCode();
  };

  return (
    <StContainer>
      <StTitle>Verifique seu e-mail</StTitle>
      <StContent>
        Enviamos um código de verificação para o email cadastrado.
      </StContent>
      <StContent>Verifique sua caixa de entrada para encontrá-lo.</StContent>
      <StOtpContainer
        onKeyDown={({ key }) => {
          if (key === 'Enter' && isOtpCompleted) {
            onClickSubmit();
          }
        }}
      >
        <StVerificationCodeTitle>Código de verificação</StVerificationCodeTitle>
        <StVerificationCodeContent>
          Insira o código recebido no campo abaixo:
        </StVerificationCodeContent>
        <StOtpInputContainer>
          <OtpInput
            inputStyle={isOtpError ? otpInputErrorStyle : otpInputStyle}
            value={otpValue}
            onChange={onChangeOtpInput}
            numInputs={otpLength}
            shouldAutoFocus
            isInputNum
            isDisabled={submitLoading}
            {...dataTestIdHandler(dataTestPrefix, 'otp')}
          />
          {isOtpError && (
            <StOtpInputErrorMessage>
              O código de verificação foi digitado incorretamente ou expirou.
              Tente novamente ou solicite um novo código.
            </StOtpInputErrorMessage>
          )}
        </StOtpInputContainer>
        <StTimerLabel>
          Tempo restante para pedir o envio de um novo código de verificação:
        </StTimerLabel>
        <StTimer>{formatTime(time)}</StTimer>
        <Button
          type="primary"
          disabled={!isOtpCompleted || !isActive}
          onClick={onClickSubmit}
          loading={submitLoading}
          {...dataTestIdHandler(dataTestPrefix, 'token-verification-submit')}
        >
          Continuar
        </Button>

        <div>
          <span>Não recebeu uma mensagem?</span>
          <StButtonLink
            type="link"
            disabled={isActive && time}
            onClick={onClickRequestNewCode}
            {...dataTestIdHandler(dataTestPrefix, 'token-resend')}
          >
            Enviar novamente
          </StButtonLink>
        </div>
      </StOtpContainer>
    </StContainer>
  );
};
